import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, UntypedFormGroup } from '@angular/forms';
import { FieldConfig } from '../field.interface';
@Component({
  selector: 'calsoft-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent implements OnInit{

  
  @Input() field: FieldConfig;
  @Input() group: UntypedFormGroup;
  @Input() index: any;
  @Input() indexInital: any;
  @Output() valueChange = new EventEmitter<any>();
  formname: string;
  constructor() {}
  optionsList: string[];
  reInit: boolean=false;
  ngOnChanges(changes: SimpleChanges): void {

    if (changes.field.currentValue) {
      let value:any= this.field.options;
      this.optionsList = value.replace(/\s/g, '').split(',');
     this.reInit=true;
     
    }
    
  }
  
  localFormControl: FormControl;
  
  ngOnInit() {

    this.localFormControl = new FormControl('');


    this.localFormControl.setValue(this.group.value[this.field.name])

    let value:any= this.field.options;
    this.optionsList = value.replace(/\s/g, '').split(',');
    console.log(this.field.options);
    this.reInit=true;
  }

  isRequired(fieldName: string): boolean {
    const control = this.group.get(fieldName);
    return control && control.hasError('required');
  }

  
  setValue(value){
    this.group.get(this.field.name).setValue(value);
   this.valueChange.emit( this.field.name);

  }
  
}
