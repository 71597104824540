import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { Constant } from 'src/app/constants/constant';
import { RatingOptionVote, Review, ReviewDetail } from 'src/app/models/review';
import { ReviewRatingService } from 'src/app/services/review-rating.service';


@Component({
  selector: 'app-review-rating',
  templateUrl: './review-rating.component.html',
  styleUrls: ['./review-rating.component.scss']
})
export class ReviewRatingComponent implements OnInit {
  @Output() closePopupEvent = new EventEmitter<void>();
  approval: any;
  firstFormGroup: UntypedFormGroup;
  requiredRatingVisible: boolean;
  text: string;
  inputs: any;
  productId: any;
  id: any;
  subProductId: any;
 

  @Input() Displaytype: any = 1;
  
  type: any;
  constructor(
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private reviewService: ReviewRatingService,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    public location: Location

  ) { }

  ngOnInit() {
    this.reviewForm();
 
    this.type=this.activatedRoute.snapshot.params['type'];

    if( this.type=='Variants'){
      this.subProductId=this.activatedRoute.snapshot.params['subProductId'];
    }else{
      this.subProductId=1;
    }
    this.requiredRatingVisible = false;
    this.text = 'SUBMIT'
    this.productId = this.activatedRoute.snapshot.params['productId'];
   
    this.getReviewById();
    
  
    
  }

  cancel() {
    this.location.back();
  }
  closePopup() {
    this.dialog.closeAll();
  }

  getReviewById() {
    let productId = this.activatedRoute.snapshot.params['productId'];
    if(this.type!='Variants'){
      this.reviewService.getReviewById(productId).subscribe(
        (response) => {
          if (response['data'] != null) {
            this.id=response['data']['reviewId'];
            this.editReview(response['data']);
            this.approval = response['data']['statusId']
          }
          else {
            this.id=0;
          }
        },
        error => {
        }
      )
    }else{
      this.reviewService.getReviewByIdSubId(productId,this.subProductId).subscribe(
        (response) => {
          if (response['data'] != null) {
            this.id=response['data']['reviewId'];
            this.editReview(response['data']);
            this.approval = response['data']['statusId']
          }
          else {
            this.id=0;
          }
        },
        error => {
        }
      )
    }

  }

  reviewForm() {
    this.firstFormGroup = this.formBuilder.group({
      reviewDetail: this.formBuilder.group({
        detailId: ['',],
        storeId: new UntypedFormControl(1),
        title: ['', [Validators.required, ]],
        detail: ['', [Validators.required, ]],
        nickname: ['',],
        customerId: ['',],
      }),
      reviewId: ['',],
      createdDate: ['',],
      entityId: new UntypedFormControl(1),
      entityPkValue: ['',],
      entityPkChildValue:[''],
      statusId: new UntypedFormControl(2),
      ratingOptionVote: this.formBuilder.array([
        this.ratingOptionVoteForm()
      ])
    })
  }


  ratingOptionVoteForm() {
    return this.formBuilder.group({
      voteId: [''],
      optionId: [''],
      remoteIp: [''],
      remoteIpLong: [''],
      customerId: [''],
      entityPkValue: [''],
      percent: [''],
      value: ['', Validators.required],
      ratingId: ['']

    });

  }
  editReview(review: Review) {
    this.firstFormGroup.patchValue({
      reviewId: review.reviewId,
      createdDate: review.createdDate,
      entityId: review.entityId,
      entityPkValue: review.entityPkValue,
      statusId: review.statusId,
      entityPkChildValue:review.entityPkChildValue
    });
    this.editReviewDetail(review.reviewDetail)
    this.firstFormGroup.setControl('ratingOptionVote', this.editAddress(review.ratingOptionVote));
  }
  editReviewDetail(reviewDetail: ReviewDetail) {
    reviewDetail = {
      detailId: reviewDetail.detailId,
      storeId: reviewDetail.storeId,
      title: reviewDetail.title,
      detail: reviewDetail.detail,
      nickname: reviewDetail.nickname,
      customerId: reviewDetail.customerId

    };
    this.firstFormGroup.get('reviewDetail').patchValue(reviewDetail)
  }

  editAddress(ratingOptionVote: RatingOptionVote[]): UntypedFormArray {
    const formArray = new UntypedFormArray([]);
    ratingOptionVote.forEach(s => {
      formArray.push(this.formBuilder.group({
        voteId: s.voteId,
        optionId: s.optionId,
        remoteIp: s.remoteIp,
        remoteIpLong: s.remoteIpLong,
        customerId: s.customerId,
        entityPkValue: s.entityPkValue,
        percent: s.percent,
        value: s.value,
        ratingId: s.ratingId,

      }));
    });
    return formArray;
  }


  getRatingData(event) {
    this.firstFormGroup.value.ratingOptionVote[0].optionId = event.count;
    this.firstFormGroup.value.ratingOptionVote[0].value = event.count;
    this.firstFormGroup.value.ratingOptionVote[0].percent = event.ratingPercent;
    this.firstFormGroup.value.ratingOptionVote[0].ratingId = 1;
    this.firstFormGroup.value.ratingOptionVote[0].customerId = event.customerId;
    this.firstFormGroup.value.ratingOptionVote[0].entityPkValue = this.productId;
    // this.firstFormGroup.controls.ratingOptionVote['controls'][0]['controls'].value['errors'].required  = false;
    // this.firstFormGroup.controls.ratingOptionVote['controls'][0]['controls'].value['status'] = "VALID";
    this.firstFormGroup.patchValue(this.firstFormGroup.value)

  }


  booleanField: any = [
    { "value": 1, "viewValue": "Approved" },
    { "value": 2, "viewValue": "Pending" },
    { "value": 3, "viewValue": "Not Approved" },
  ]


  createReview() {
    this.requiredRatingVisible = true;
    //this.firstFormGroup.value.dob = "2019-06-23 11:23:23"
    this.firstFormGroup.value.statusId = 2;
    this.firstFormGroup.value.entityId = 1;
    this.firstFormGroup.value.storeId = 1;
    this.firstFormGroup.value.entityPkChildValue = this.subProductId;
    this.firstFormGroup.value.operationType = "CHANGE";
    this.firstFormGroup.value.entityPkValue = this.productId;
    this.firstFormGroup.value.reviewDetail.customerId = 0;
    if (this.text == 'SUBMIT' && this.firstFormGroup.valid) {
      this.text = "Processing"
      this.firstFormGroup.value.operationType = "CHANGE";
      this.firstFormGroup.value.entityPkValue = this.route.snapshot.params['productId'];
      this.reviewService.createReview(this.firstFormGroup.value).subscribe(
        response => {
          //   this.router.navigate(['/users']);
          if (response['status_code'] == Constant.RESPONSE_SUCCESS) {
            this.snackBar.open("Review Submitted Successfully", '×', { panelClass: 'success', verticalPosition: 'bottom', duration: 3000 });
            this.location.back();
          }
          else {
            this.text = "Processing"
            this.snackBar.open(response['status_message'], '×', { panelClass: 'error', verticalPosition: 'bottom', duration: 3000 });
          }
        }, error => {
          this.text = "Processing"
        }
      );
    }
    else {
      this.firstFormGroup.markAllAsTouched();
    }
  }

  validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }

  validation = {

    'title': [
      { type: 'required', message: 'Please fill out this field.' },
    ],
    'detail': [
      { type: 'required', message: 'Please fill out this field.' },

    ]

  }

}
