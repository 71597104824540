import { Component, EventEmitter, Output, SimpleChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Constant } from 'src/app/constants/constant';
import { ProductService } from 'src/app/services/product.service';
import { environment } from 'src/environments/environment';
import { FieldConfig } from '../field.interface';

@Component({
  selector: 'calsoft-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent {
  @Output() valueChange = new EventEmitter<any>();
  field: FieldConfig;
  group: UntypedFormGroup;
  text: string;
  public serverPath = environment.commonImageApi + 'gallery/';
  value: any;
  reInit: boolean=false;
  imageList: any[];
  constructor(
    private snackBar: MatSnackBar,
    private productService: ProductService,) { }

    ngOnChanges(changes: SimpleChanges): void {

      if (changes.field.currentValue) {
        this.value= this.group.value[this.field.name];       
      }
      
    }
    

  ngOnInit(): void {
    this.text = "Save"
  
  }
  imageVisible: boolean = true;
  imageDelete: boolean;
  imagesToRemoved: any = [];
 

  getResult(event) {
    this.imageVisible = true;
    this.imageList=[];
    event.productEntityMediaGallery.forEach(element => {

      let object ={
        value:'',
        imageName: element['fileName'],
      }

      this.imageList.push(object);

    

       
  });



  }

  


  removeImageOption(index: number): void {
    if (index > -1 && index < this.imageList.length) {
      this.imageList.splice(index, 1);
    }
  }


  cancelUpload() {
    this.imageVisible = true;
  }

  logo() {
    this.imageVisible = false;
  }


  newForm(){
    this.valueChange.emit(this.imageList);
  }

}
