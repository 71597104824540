
<div class="img" *ngIf="reInit" [formGroup]="group">

    <div class="tiltle">
        <h2 class="padding-10">
            {{field.name | titlecase}}
        </h2>

    </div>

    <ng-container *ngFor="let image of imageValues" >
        <img  (click)="setValue(image.value)" class="icon" [alt]="field.customfield3" [src]="serverPath + image.image">
      </ng-container>

</div>